.announcements {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;
  .title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: #090A0B;
  }
  .subtitle {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 22px;
    color: #4A4B57;
  }
  .note {
    margin-bottom: 10px;
    font-size: 14px;
    font-style: italic;
    color: #6c757d;
  }
  input, textarea {
    &:disabled {
      opacity: .5;
    }
  }
}
